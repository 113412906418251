<template>
      <v-form
          ref="formAcademicDetails"
          v-model="validAcademicDetails"
          readonly
      >
      <div class="container">
      <v-data-table
       v-show="academics.length>0"
      :headers="academicsheaders"
      :items="academics"
      :server-items-length="academics.length"
      hide-action
      :hide-default-footer="true"
      item-key="props.index">
    </v-data-table>
    <!-- <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addAcademicRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout> -->
    <br><br><br>
    <label><b>MEMBERSHIP OF PROFESSIONAL ASSOCIATIONS </b></label>
    <br>
    {{proffesionalAssociations}}
    <br>
    <br>
    <v-divider></v-divider>
    </div>
    </v-form>
</template>
<script>
export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    academicsheaders: [
      { text: 'Degree/Diploma & Specialization', value: 'specialization' },
      { text: 'College/University/Institute', value: 'institute' },
      { text: 'Class/Grade', value: 'marks' },
      { text: 'Year From', value: 'yearFrom' },
      { text: 'To', value: 'yearTo' },
      { text: 'Actions', value: 'action' },
    ],
    submittedId: '',
    academics: [],
    proffesionalAssociations: '',
    validAcademicDetails: true,
    loading: false,
    refKey: '',
  }),
  methods: {
    validateAcademicDetails() {
      if (this.$refs.formAcademicDetails.validate()) {
      //  this.submitAcademicDetails();
      }
    },
    async getAcademicDetails() {
      const academicDetails = await this.firebase.firestore().collection('academicDetails').doc(this.refKey);
      const doc = await academicDetails.get();
      const info = doc.data();
      this.academics = info.academics;
      this.proffesionalAssociations = info.proffesionalAssociations;
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await type.get();
      const info = doc.data();
      this.refKey = info.academicDetails;
    },
    addAcademicRow() {
      const newRow = {
        specialization: '',
        institute: '',
        marks: '',
        id: this.academics.length,
        yearFrom: '',
        yearTo: '',
      };
      this.academics.push(newRow);
    },
    deleteAcademicRow(index) {
      this.academics.splice(index, 1);
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getAcademicDetails();
        }
      }
    },
  },
  mounted() {
    this.getRefKey();
  },
};
</script>
