<template>
  <v-form
      ref="formPersonalDetails"
      v-model="validPersonalDetails"
      readonly
  >
        <div class="container">
      <v-img
      v-if="downloadURL != '' "
      :src=downloadURL
      width="25%"/>
      <v-row>
      <v-col md="4"><v-text-field
        v-model="applicantFirstName"
        class="required"
        prepend-icon="mdi-account"
        label="First Name"
        required
      ></v-text-field></v-col>
      <v-col md="4"><v-text-field
        v-model="applicantLastName"
        class="required"
        prepend-icon="mdi-account"
        label="Last Name"
        required
      ></v-text-field></v-col>
      <v-col md="4"><v-text-field
        v-model="applicantSurname"
        class="required"
        prepend-icon="mdi-account"
        label="Surname"
        required
      ></v-text-field></v-col>
          </v-row>
          <v-text-field
        v-model="fatherName"
        prepend-icon="mdi-account"
        label="Father Name"
        class="required"
        required
      ></v-text-field>
      <v-dialog
          persistent
          v-model="officialModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
            name="officialDOB"
            v-model="officialDOB"
            label="offical Date of Birth as in Aadhaar/PAN card"
            slot="activator"
            id="officialDOB"
            prepend-icon="mdi-calendar"
            v-on="on"
            :class="{'required': type}"
          ></v-text-field>
          </template>
          <v-date-picker v-model="officialDate" scrollable actions>
            <template v-scope="{ saveTheOfficialDate, cancelTheOfficialDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheOfficialDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheOfficialDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          persistent
          v-model="actualModal"
          min-width="250px"
          width="41vh"
          >
          <template v-slot:activator="{ on }">
          <v-text-field
            name="actualDOB"
            v-model="actualDOB"
            label="actual Date of Birth"
            slot="activator"
            id="actualDOB"
            prepend-icon="mdi-calendar"
            v-on="on"
            :class="{'required': type}"
          ></v-text-field>
          </template>
          <v-date-picker v-model="actualDate" scrollable actions>
            <template v-scope="{ saveTheActualDate, cancelTheActualDate }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelTheActualDate">Cancel</v-btn>
                <v-btn text color="primary" @click="saveTheActualDate">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
        <v-text-field
        v-model="bloodGroup"
        prepend-icon="mdi-water-plus"
        label="Blood Group"
        :counter="3"
        required
      ></v-text-field>
      <v-text-field
      prepend-icon="mdi-card"
        v-model="aadhaarNumber"
        label="UIDAI NUMBER (AADHAAR)"
        required
      ></v-text-field>
      <v-text-field
      prepend-icon="mdi-card"
        v-model="panNumber"
        label="PAN NUMBER"
        required
      ></v-text-field>
      <v-label>Present Address</v-label>
      <v-text-field
        v-model="presentStreet"
        label="Apartment/Door number and Street"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentLocality"
        label="Locality"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentTown"
        label="City/District/Town"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentBlock"
        label="Sub-District/Block"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="presentLandmark"
        label="Landmark"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentState"
        label="State"
        required
      ></v-text-field>
      <v-text-field
        v-model="presentPincode"
        label="Pincode"
        required
      ></v-text-field>
       <v-text-field
        v-model="presentCountry"
        label="Country"
        required
      ></v-text-field>
      <v-divider></v-divider>
      <!-- <v-radio-group v-model="sameAddress">
       <label><b>Is permanent address same as present address</b></label>
        <v-radio
          label= "Yes"
          :value= "true"
          v-on:change="updatePermanantAddress"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
          v-on:change="clearPermanantAddress"
        ></v-radio>
      </v-radio-group> -->
      <label class="active" for="address"><b> Permanant Address </b></label>
      <v-text-field
        v-model="permanantStreet"
        label="Apartment/Door number and Street"
        :disabled="sameAddress"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantLocality"
        :disabled="sameAddress"
        label="Locality"
        required
      ></v-text-field>
    <v-text-field
        v-model="permanantTown"
        label="City/District/Town"
        :disabled="sameAddress"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantBlock"
        label="Sub-District/Block"
        :disabled="sameAddress"
        class="required"
        required
      ></v-text-field>
    <v-text-field
        v-model="permanantLandmark"
        :disabled="sameAddress"
        label="Landmark"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantState"
        :disabled="sameAddress"
        label="State"
        required
      ></v-text-field>
      <v-text-field
        v-model="permanantPincode"
        :disabled="sameAddress"
        label="Pincode"
        required
      ></v-text-field>
       <v-text-field
        v-model="permanantCountry"
        :disabled="sameAddress"
        label="Country"
        required
      ></v-text-field>
      <v-text-field
          prepend-icon="mdi-phone"
          label="Residence Number"
          v-model="residenceNumber"
      ></v-text-field>
      <v-text-field
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="personalNumber"
      ></v-text-field>
      <v-text-field
          prepend-icon="mdi-phone"
          label="Official Number"
          v-model="officialNumber"
      ></v-text-field>
      <v-text-field
       prepend-icon="mdi-email"
        v-model="email"
        label="E-mail"
        required
      ></v-text-field>
      <v-data-table
      v-show="langs.length > 0"
      :headers="langHeaders"
      :items="langs"
      :server-items-length="langs.length"
      :items-per-page-options="5"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.read="props">
            <v-checkbox v-model="props.item.read" ></v-checkbox>
      </template>
      <template v-slot:item.write="props">
            <v-checkbox v-model="props.item.write" ></v-checkbox>
      </template>
      <template v-slot:item.speak="props">
            <v-checkbox v-model="props.item.speak" ></v-checkbox>
      </template>
    </v-data-table>
    <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <v-radio-group v-model="speciallyAbled">
       <label><b>SPECIALLY-ABLED ? </b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
    </v-radio-group>
    </div>
    <div v-show="speciallyAbled">
    <label><b>Please provide details</b></label>
    <br>
    {{speciallyAbledDetails}}
    <v-divider></v-divider>
    </div>
    <br>
    <br>
    <br>
          </div>
          </v-form>
</template>

<script>
import moment from 'moment';

export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    validPersonalDetails: true,
    sameAddress: false,
    loading: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    aadharRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 12) || 'Aadhaar number must be 12 digits',
    ],
    panRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z0-9]/gi.test(v) || 'Field only accepts numbers and text',
      (v) => (v && v.length === 10) || 'PAN  must be 10 characters',
      (v) => /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(v) || 'please enter valid PAN',
    ],
    bloodGroupRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 3) || 'Invalid Blood Group, Field must be maximum 3 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    pincodeRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 6) || 'Please enter valid pincode',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    submittedId: '',
    applicantFirstName: '',
    applicantLastName: '',
    applicantSurname: '',
    fatherName: '',
    martialStatus: false,
    bloodGroup: '',
    aadhaarNumber: '',
    panNumber: '',
    presentStreet: '',
    presentLocality: '',
    presentTown: '',
    presentLandmark: '',
    presentState: '',
    presentPincode: '',
    presentCountry: '',
    presentBlock: '',
    permanantBlock: '',
    permanantStreet: '',
    permanantLocality: '',
    permanantTown: '',
    permanantLandmark: '',
    permanantState: '',
    permanantPincode: '',
    permanantCountry: '',
    residenceNumber: '',
    personalNumber: '',
    officialNumber: '',
    speciallyAbled: false,
    notSpeciallyAbled: false,
    speciallyAbledDetails: '',
    downloadURL: '',
    langHeaders: [
      {
        text: 'Language',
        value: 'lang',
        align: 'left',
        sortable: false,
      },
      { text: 'Read', value: 'read' },
      { text: 'Write', value: 'write' },
      { text: 'Speak', value: 'speak' },
    ],
    langs: [],
    type: false,
    refKey: '',
    officialDate: '',
    officialModal: false,
    officialDOB: '',
    actualDate: '',
    actualModal: false,
    actualDOB: '',
    currentDate: moment(new Date()),
  }),
  methods: {
    inputHandler(date) {
      let dt = date;
      if (typeof dt === 'string') {
        dt = moment(dt).toDate();
      }
      this.isActive = false;
      this.theDate = dt;
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      // console.log('Dialog closed');
    },
    cancelTheOfficialDate() {
      this.officialModal = false;
    },
    cancelTheActualDate() {
      this.actualModal = false;
    },
    saveTheOfficialDate() {
      this.officialModal = false;
      this.officialDOB = this.formatedDate(this.officialDate);
    },
    saveTheActualDate() {
      this.actualModal = false;
      this.actualDOB = this.formatedDate(this.actualDate);
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    updatedTask() {
      // console.log('hi');
    },
    validatePersonalDetails() {
      if (this.$refs.formPersonalDetails.validate()) {
      //  this.submitPersonalDetails();
      }
    },
    updatePermanantAddress() {
      this.permanantStreet = this.presentStreet;
      this.permanantLocality = this.presentLocality;
      this.permanantTown = this.presentTown;
      this.permanantLandmark = this.presentLandmark;
      this.permanantState = this.presentState;
      this.permanantPincode = this.presentPincode;
      this.permanantCountry = this.presentCountry;
    },
    clearPermanantAddress() {
      this.permanantStreet = '';
      this.permanantLocality = '';
      this.permanantTown = '';
      this.permanantLandmark = '';
      this.permanantState = '';
      this.permanantPincode = '';
      this.permanantCountry = '';
    },
    async getPersonalDetails() {
      const personalDetails = await this.firebase.firestore().collection('formData').doc(this.refKey);
      const doc = await personalDetails.get();
      const info = doc.data();
      this.applicantFirstName = info.applicantFirstName;
      this.applicantLastName = info.applicantLastName;
      this.applicantSurname = info.applicantSurname;
      this.fatherName = info.fatherName;
      this.aadhaarNumber = info.aadhaarNumber;
      this.bloodGroup = info.bloodGroup;
      this.langs = info.communicationLanguages;
      this.email = info.email;
      this.martialStatus = info.martialStatus;
      this.officialNumber = info.officialNumber;
      this.panNumber = info.panNumber;
      this.permanantAddres = info.permanantAddres;
      this.personalNumber = info.personalNumber;
      this.presentAddress = info.presentAddress;
      this.residenceNumber = info.residenceNumber;
      this.speciallyAbled = info.speciallyAbled;
      this.speciallyAbledDetails = info.speciallyAbledDetails;
      const permanantDict = info.permanantAddres;
      const presentDict = info.presentAddress;
      this.permanantStreet = permanantDict.street;
      this.permanantLocality = permanantDict.locality;
      this.permanantTown = permanantDict.city;
      this.permanantLandmark = permanantDict.landmark;
      this.permanantState = permanantDict.state;
      this.permanantPincode = permanantDict.pincode;
      this.permanantCountry = permanantDict.country;
      this.presentStreet = presentDict.street;
      this.presentLocality = presentDict.locality;
      this.presentTown = presentDict.city;
      this.presentLandmark = presentDict.landmark;
      this.presentState = presentDict.state;
      this.presentPincode = presentDict.pincode;
      this.presentCountry = presentDict.country;
      this.permanantBlock = permanantDict.block;
      this.presentBlock = presentDict.block;
      this.officialDOB = info.officialDOB;
      this.actualDOB = info.actualDOB;
      this.downloadURL = await this.firebase.storage().ref()
        .child(`images/${this.applicantFirstName}${this.applicantLastName}${this.personalNumber.substring(9)}.jpeg`.replace(/\s/g, '')).getDownloadURL();
    },
    async getType() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await type.get();
      const info = doc.data();
      this.type = !info.postRecruitment;
      this.refKey = info.formData;
    },
  },
  watch: {
    refKey() {
      if (this.currentStep === 1) {
        if (this.refKey !== undefined) {
          if (this.refKey !== '') {
            this.getPersonalDetails();
          }
        }
      }
    },
  },
  mounted() {
    // console.log(this.docKey, 'its the email*******');
    this.getType();
  },
  computed: {
    // hmmn
  },
};
</script>
<style scoped>
img {
     -webkit-print-color-adjust: exact;
}
</style>
