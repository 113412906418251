<template>
  <v-form
          ref="formRemunerationDetails"
          v-model="validRemunerationDetails"
          readonly
        >
        <div class="container">
          <label><b>On Joining </b></label>
          <v-text-field
            label="Basic (₹)"
            v-model="joiningBasic"
          ></v-text-field>
          <v-text-field
            label="DA (₹)"
            v-model="joiningDA"
          ></v-text-field>
          <v-text-field
            label="HRA (₹)"
            v-model="joiningHRA"
          ></v-text-field>
          <v-text-field
            label="Conveyance (₹)"
            v-model="joiningConveyance"
          ></v-text-field>
          <v-text-field
            label="Any other allowance (₹)"
            v-model="joiningOtherAllowance"
          ></v-text-field>
          <v-text-field
            label="Gross Salary (₹)"
            v-model="joiningGrossSalary"
          ></v-text-field>
          <v-text-field
            label="LTA (₹)"
            v-model="joiningLTA"
          ></v-text-field>
          <v-text-field
            label="Medical (₹)"
            v-model="joiningMedical"
          ></v-text-field>
          <v-text-field
            label="Annual Bonus (₹)"
            v-model="joiningAnnualBonus"
          ></v-text-field>
          <v-text-field
            label="Club Membership (₹)"
            v-model="joiningClubMembership"
          ></v-text-field>
          <v-text-field
            label="Others (₹)"
            v-model="joiningOthers"
          ></v-text-field>
          <v-text-field
            label="Monthly CTC (₹)"
            v-model="joiningMonthlyCTC"
          ></v-text-field>
          <v-text-field
            label="Yearly CTC (monthly CTC x 12) (₹)"
            v-model="joiningYearlyCTC"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <label><b>At Present </b></label>
          <v-text-field
            label="Basic (₹)"
            v-model="presentBasic"
          ></v-text-field>
          <v-text-field
            label="DA (₹)"
            v-model="presentDA"
          ></v-text-field>
          <v-text-field
            label="HRA (₹)"
            v-model="presentHRA"
          ></v-text-field>
          <v-text-field
            label="Conveyance (₹)"
            v-model="presentConveyance"
          ></v-text-field>
          <v-text-field
            label="Any other allowance (₹)"
            v-model="presentOtherAllowance"
          ></v-text-field>
          <v-text-field
            label="Gross Salary (₹)"
            v-model="presentGrossSalary"
          ></v-text-field>
          <v-text-field
            label="LTA (₹)"
            v-model="presentLTA"
          ></v-text-field>
          <v-text-field
            label="Medical (₹)"
            v-model="presentMedical"
          ></v-text-field>
          <v-text-field
            label="Annual Bonus (₹)"
            v-model="presentAnnualBonus"
          ></v-text-field>
          <v-text-field
            label="Club Membership (₹)"
            v-model="presentClubMembership"
          ></v-text-field>
          <v-text-field
            label="Others (₹)"
            v-model="presentOthers"
          ></v-text-field>
          <v-text-field
            label="Monthly CTC (₹)"
            v-model="presentMonthlyCTC"
          ></v-text-field>
          <v-text-field
            label="Yearly CTC (monthly CTC x 12) (₹)"
            v-model="presentYearlyCTC"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <label ><b>RETIREMENT BENEFITS IN YOUR CURRENT ORGANIZATION</b></label>
          <v-checkbox
            v-model="providentFund"
            :label="`Provident Fund `"
          ></v-checkbox>
          <v-checkbox
            v-model="gratuity"
            :label="`Gratuity`"
          ></v-checkbox>
          <v-checkbox
            v-model="pension"
            :label="`Pension/Superannuation`"
          ></v-checkbox>
          <v-checkbox
            v-model="othersRetirmentBenefit"
            :label="`Others`"
          ></v-checkbox>
          <div  v-if="othersRetirmentBenefit">
          <label><b>Please mention other retirment benefits</b></label>
          <v-textarea rows="2" row-height="18"  name="proffesionalAssociations"
          v-model="othersRetirmentBenefitDescription" :rules="basicRules"></v-textarea>
          </div>
          <label><b>LEAVE BENEFITS IN CURRENT YOUR ORGANIZATION </b></label>
          <v-data-table
       v-show="leaves.length>0"
      :headers="leavesheaders"
      :items="leaves"
      :server-items-length="leaves.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
    </v-data-table>
    <!-- <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addLeavesRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout> -->
    <br><br><br>
          <label><b>YOUR EXPECTED REMUNERATION </b></label>
          <v-text-field
            label="Monthly Fixed Takehome (₹)"
            v-model="expectedMonthlyFixedTakeHome"
          ></v-text-field>
          <v-text-field
            label="Yearly Fixed CTC (₹)"
            v-model="expectedYearlyFixedCTC"
          ></v-text-field>
          <v-divider>
          </v-divider>
          <v-text-field
            label="Time required for joining our Organization (Notice Period; if selected) in days"
            v-model="noticePeriodInDays"
          ></v-text-field>
          <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
            <v-radio-group v-model="relocationWill">
          <label><b>Are you open to relocate if required</b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
            </v-radio-group>
          </div>
          <div v-show="!relocationWill">
          <label><b>Please provide details</b></label>
          <br>
          {{notRelocateDetails}}
          <v-divider></v-divider>
          </div>
           <div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
          <v-radio-group v-model="presentServiceBond">
       <label><b>Are you under any service bond/contract with your present organization? </b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
          </div>
          <div v-show="presentServiceBond">
          <label ><b>Please provide details</b></label>
          <br>
          {{serviceBondDetails}}
          <v-divider></v-divider>
          </div>
          <v-radio-group v-model="refferalsInfo">
        <label ><b>Is any of your Relative(s) / Friend(s) / Acquaintance(s) currently working with any Manipal Group companies ?   </b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
          <label v-show="refferalsInfo"><b>Please provide details</b></label>
          <v-data-table
       v-show="refferalsInfo"
      :headers="refferalsheaders"
      :items="refferals"
      :server-items-length="refferals.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
    </v-data-table>
    <!-- <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addRefferalsRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout> -->
    <br><br><br>
    <v-radio-group v-model="homeInternetConnectivity">
          <label ><b>Do you have good INTERNET Connectivity at HOME</b></label>
    <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
        <br>
    <label><b>Self : (INTRODUCTION Please specify the details)</b></label>
    <br>
    {{selfIntro}}
    <v-divider></v-divider>
    <br>
    <br>
    <br>
    <label><b>Please explain in brief why you consider yourself suitable for the position applied for? </b></label>
    <br>
    {{jobSuitableReason}}
    <v-divider></v-divider>
    <br>
    <br>
    <br>
    <label><b>Which functional area do you prefer?</b></label>
    <br>
    {{functionalAreaPreference}}
    <v-divider></v-divider>
    <br>
    <br>
    <br>
    <label><b>Are you planning for higher studies?</b></label>
    <br>
    {{higherStudiesDetails}}
    <v-divider></v-divider>
    <br>
    <br>
    <br>
    <label><b>Weekly off day(s) in your present employment </b></label>
    <br>
    {{weeklyOffDaysDetails}}
    <v-divider></v-divider>
    <br>
    <br>
    <br>
    <br>
    <v-radio-group v-model="previouslyInterviedByUs">
          <label ><b>Have you been previously tested/interviewed by us/Manipal group companies ? </b></label>
          <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
        <br>
        <label v-show="previouslyInterviedByUs"><b>Please provide details</b></label>
        <v-data-table
       v-show="previouslyInterviedByUs"
      :headers="previouslyTestedheaders"
      :items="previouslyTested"
      :server-items-length="previouslyTested.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.date="props">
        {{ props.item.date == '' ? "not provided" : formatedDate(props.item.date) }}
      </template>
    </v-data-table>
    <br><br><br>
        </div>
          </v-form>
</template>
<script>
import moment from 'moment';

export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    submittedId: '',
    validRemunerationDetails: true,
    loading: false,
    joiningBasic: '',
    joiningDA: '',
    joiningHRA: '',
    joiningConveyance: '',
    joiningOtherAllowance: '',
    joiningGrossSalary: '',
    joiningLTA: '',
    joiningMedical: '',
    joiningAnnualBonus: '',
    joiningClubMembership: '',
    joiningOthers: '',
    joiningMonthlyCTC: '',
    joiningYearlyCTC: '',
    presentBasic: '',
    presentDA: '',
    presentHRA: '',
    presentConveyance: '',
    presentOtherAllowance: '',
    presentGrossSalary: '',
    presentLTA: '',
    presentMedical: '',
    presentAnnualBonus: '',
    presentClubMembership: '',
    presentOthers: '',
    presentMonthlyCTC: '',
    presentYearlyCTC: '',
    providentFund: false,
    gratuity: false,
    pension: false,
    othersRetirmentBenefit: false,
    othersRetirmentBenefitDescription: '',
    expectedMonthlyFixedTakeHome: '',
    expectedYearlyFixedCTC: '',
    noticePeriodInDays: '',
    relocationWill: false,
    notRelocateDetails: '',
    presentServiceBond: false,
    serviceBondDetails: '',
    refferalsInfo: false,
    homeInternetConnectivity: false,
    selfIntro: '',
    jobSuitableReason: '',
    functionalAreaPreference: '',
    higherStudiesDetails: '',
    weeklyOffDaysDetails: '',
    previouslyInterviedByUs: false,
    leaves: [],
    leavesheaders: [
      { text: 'Leave Name', value: 'name' },
      { text: 'Leaves/Year', value: 'leavesPerYear' },
      { text: 'Details(if any)', value: 'details' },
      { text: 'Actions', value: 'action' },
    ],
    previouslyTested: [],
    previouslyTestedheaders: [
      { text: 'Company Name', value: 'company' },
      { text: 'Position Name', value: 'name' },
      { text: 'Job Location', value: 'location' },
      { text: 'Date of Interview/Test', value: 'date' },
      { text: 'Actions', value: 'action' },
    ],
    refferals: [],
    refferalsheaders: [
      { text: 'Company Name', value: 'company' },
      { text: 'Relative / Friend / Acquaintance Name(s)', value: 'name' },
      { text: 'Employee Code', value: 'code' },
      { text: 'Designation', value: 'designation' },
      { text: 'Job Location', value: 'location' },
      { text: 'Association with You', value: 'association' },
      { text: 'Actions', value: 'action' },
    ],
    refKey: '',
  }),
  methods: {
    validateRemunerationDetails() {
      if (this.$refs.formRemunerationDetails.validate()) {
      //  this.submitRemunerationDetails();
      }
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    async getRemunerationDetails() {
      const remunerationDetails = await this.firebase.firestore().collection('remunerationDetails').doc(this.refKey);
      const doc = await remunerationDetails.get();
      const info = doc.data();
      this.joiningBasic = info.joiningBasic;
      this.joiningDA = info.joiningDA;
      this.joiningHRA = info.joiningHRA;
      this.joiningConveyance = info.joiningConveyance;
      this.joiningOtherAllowance = info.joiningOtherAllowance;
      this.joiningGrossSalary = info.joiningGrossSalary;
      this.joiningLTA = info.joiningLTA;
      this.joiningMedical = info.joiningMedical;
      this.joiningAnnualBonus = info.joiningAnnualBonus;
      this.joiningClubMembership = info.joiningClubMembership;
      this.joiningOthers = info.joiningOthers;
      this.joiningMonthlyCTC = info.joiningMonthlyCTC;
      this.joiningYearlyCTC = info.joiningYearlyCTC;
      this.presentBasic = info.presentBasic;
      this.presentDA = info.presentDA;
      this.presentHRA = info.presentHRA;
      this.presentConveyance = info.presentConveyance;
      this.presentOtherAllowance = info.presentOtherAllowance;
      this.presentGrossSalary = info.presentGrossSalary;
      this.presentLTA = info.presentLTA;
      this.presentMedical = info.presentMedical;
      this.presentAnnualBonus = info.presentAnnualBonus;
      this.presentClubMembership = info.presentClubMembership;
      this.presentOthers = info.presentOthers;
      this.presentMonthlyCTC = info.presentMonthlyCTC;
      this.presentYearlyCTC = info.presentYearlyCTC;
      this.providentFund = info.providentFund;
      this.gratuity = info.gratuity;
      this.pension = info.pension;
      this.othersRetirmentBenefit = info.othersRetirmentBenefit;
      this.expectedMonthlyFixedTakeHome = info.expectedMonthlyFixedTakeHome;
      this.expectedYearlyFixedCTC = info.expectedYearlyFixedCTC;
      this.noticePeriodInDays = info.noticePeriodInDays;
      this.relocationWill = info.relocationWill;
      this.notRelocateDetails = info.notRelocateDetails;
      this.presentServiceBond = info.presentServiceBond;
      this.serviceBondDetails = info.serviceBondDetails;
      this.refferalsInfo = info.refferalsInfo;
      this.homeInternetConnectivity = info.homeInternetConnectivity;
      this.selfIntro = info.selfIntro;
      this.jobSuitableReason = info.jobSuitableReason;
      this.functionalAreaPreference = info.functionalAreaPreference;
      this.higherStudiesDetails = info.higherStudiesDetails;
      this.weeklyOffDaysDetails = info.weeklyOffDaysDetails;
      this.previouslyInterviedByUs = info.previouslyInterviedByUs;
      this.leaves = info.leaves;
      this.previouslyTested = info.previouslyTested;
      this.refferals = info.refferals;
      this.othersRetirmentBenefitDescription = info.othersRetirmentBenefitDescription;
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await type.get();
      const info = doc.data();
      this.refKey = info.remunerationDetails;
    },
    addRefferalsRow() {
      const newRow = {
        name: '',
        code: '',
        designation: '',
        location: '',
        association: '',
      };
      this.refferals.push(newRow);
    },
    deleteRefferalsRow(index) {
      this.refferals.splice(index, 1);
    },
    addLeavesRow() {
      const newRow = {
        name: '',
        leavesPerYear: '',
        details: '',
      };
      this.leaves.push(newRow);
    },
    deleteLeavesRow(index) {
      this.leaves.splice(index, 1);
    },
    addPreviouslyTestedRow() {
      const newRow = {
        name: '',
        location: '',
        date: '',
      };
      this.previouslyTested.push(newRow);
    },
    deletePreviouslyTestedRow(index) {
      this.previouslyTested.splice(index, 1);
    },
    deleteExpRow(index) {
      this.experience.splice(index, 1);
    },
    addExpRow() {
      const newRow = {
        yearFrom: '',
        yearTo: '',
        specialization: '',
        institute: '',
        marks: '',
        id: this.experience.length,
      };
      this.experience.push(newRow);
    },
  },
  mounted() {
    this.addRefferalsRow();
    this.addLeavesRow();
    this.addPreviouslyTestedRow();
    this.getRefKey();
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getRemunerationDetails();
        }
      }
    },
  },
  computed: {
    // hmnn
  },
};
</script>
