<template>
<v-form
          ref="formReferencesDetails"
          v-model="validReferenceDetails"
          readonly
        >
        <div class="containter">
          <label><b>First Reference</b></label>
        <v-text-field
        v-model="firstRefName"
        :counter="100"
        prepend-icon="mdi-account"
        label="Name"
        required
      ></v-text-field>
    <v-label>Address</v-label>
    <v-text-field
        v-model="firstRefStreet"
        label="Street"
      ></v-text-field>
    <v-text-field
        v-model="firstRefLocality"
        label="Locality"
      ></v-text-field>
    <v-text-field
        v-model="firstRefTown"
        label="City/District/Town"
      ></v-text-field>
      <v-text-field
        v-model="firstRefBlock"
        label="Sub-District/Block"
      ></v-text-field>
    <v-text-field
        v-model="firstRefLandmark"
        label="Landmark"
      ></v-text-field>
      <v-text-field
        v-model="firstRefState"
        label="State"
      ></v-text-field>
      <v-text-field
        v-model="firstRefPincode"
        label="Pincode"
      ></v-text-field>
       <v-text-field
        v-model="firstRefCountry"
        label="Country"
      ></v-text-field>
<v-text-field
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="firstRefPhoneNumber"
      ></v-text-field>
<v-text-field
       prepend-icon="mdi-email"
        v-model="firstRefEmail"
        label="E-mail"
        required
      ></v-text-field>
<div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <label><b>Association </b></label>
    <v-checkbox
        v-model="firstRefAssociationClient"
        :label="`Client/Customer`"
      ></v-checkbox>
      <v-checkbox
        v-model="firstRefAssociationProfessional"
        :label="`Professional Contact`"
      ></v-checkbox>
      <v-checkbox
        v-model="firstRefAssociationColleague"
        :label="`Former Colleague/Manager/Employer`"
      ></v-checkbox>
    </div>
    <v-divider>
    </v-divider>
    <label><b>Second Reference</b></label>
        <v-text-field
        v-model="secondRefName"
        :counter="100"
        prepend-icon="mdi-account"
        label="Name"
        required
      ></v-text-field>
<v-label>Address</v-label>
<v-text-field
        v-model="secondRefStreet"
        label="Street"
      ></v-text-field>
    <v-text-field
        v-model="secondRefLocality"
        label="Locality"
      ></v-text-field>
    <v-text-field
        v-model="secondRefTown"
        label="City/District/Town"
      ></v-text-field>
    <v-text-field
        v-model="secondRefBlock"
        label="Sub-District/Block"
      ></v-text-field>
    <v-text-field
        v-model="secondRefLandmark"
        label="Landmark"
      ></v-text-field>
      <v-text-field
        v-model="secondRefState"
        label="State"
      ></v-text-field>
      <v-text-field
        v-model="secondRefPincode"
        label="Pincode"
      ></v-text-field>
       <v-text-field
        v-model="secondRefCountry"
        label="Country"
      ></v-text-field>
<v-text-field
          prepend-icon="mdi-phone"
          label="Personal Number"
          v-model="secondRefPhoneNumber"
      ></v-text-field>
<v-text-field
       prepend-icon="mdi-email"
        v-model="secondRefEmail"
        label="E-mail"
        required
      ></v-text-field>
<div style="text-align:left;border: 0.1px;border-style:solid;border-color:grey;">
    <label ><b>Association </b></label>
    <v-checkbox
        v-model="secondRefAssociationClient"
        :label="`Client/Customer`"
      ></v-checkbox>
      <v-checkbox
        v-model="secondRefAssociationProfessional"
        :label="`Professional Contact`"
      ></v-checkbox>
      <v-checkbox
        v-model="secondRefAssociationColleague"
        :label="`Former Colleage/Manager/Employer`"
      ></v-checkbox>
    </div>
    <v-divider></v-divider>
    <v-checkbox
        v-model="finalAgreement"
        :label="`I, the above named applicant do hereby solemnly declare that the above information is
        correct to the best of my knowledge and belief.
        In case any particulars are found to be incorrect,
        it will be open to the management to dispense with my services without any notice. *`"
        required
      ></v-checkbox>
      <div class="centerDiv">
        <v-btn class="btn" color="primary" width="23%" height="40px" :href="downloadURL" v-if="downloadURL != '' && downloadURL != undefined" >
         Download Resume
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    loading: false,
    validReferenceDetails: true,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    pincodeRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
      (v) => (v && v.length === 6) || 'Please enter valid pincode',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    submittedId: '',
    firstRefAssociationClient: false,
    firstRefAssociationProfessional: false,
    firstRefAssociationColleague: false,
    secondRefAssociationClient: false,
    secondRefAssociationProfessional: false,
    secondRefAssociationColleague: false,
    finalAgreement: false,
    firstRefName: '',
    firstRefStreet: '',
    firstRefLocality: '',
    firstRefTown: '',
    firstRefLandmark: '',
    firstRefState: '',
    firstRefPincode: '',
    firstRefCountry: '',
    firstRefPhoneNumber: '',
    firstRefEmail: '',
    firstRefBlock: '',
    secondRefBlock: '',
    secondRefName: '',
    secondRefStreet: '',
    secondRefLocality: '',
    secondRefTown: '',
    secondRefLandmark: '',
    secondRefState: '',
    secondRefPincode: '',
    secondRefCountry: '',
    secondRefPhoneNumber: '',
    secondRefEmail: '',
    downloadURL: '',
    fileName: '',
    refKey: '',
  }),
  methods: {
    validateReferencesDetails() {
      if (this.$refs.formReferencesDetails.validate()) {
      //  this.submitReferenceDetails();
      }
    },
    async getReferencesDetails() {
      const referencesDetails = await this.firebase.firestore().collection('referenceDetails').doc(this.refKey);
      const doc = await referencesDetails.get();
      const info = doc.data();
      this.firstRefAssociationClient = info.firstRefAssociationClient;
      this.firstRefAssociationProfessional = info.firstRefAssociationProfessional;
      this.firstRefAssociationColleague = info.firstRefAssociationColleague;
      this.secondRefAssociationClient = info.secondRefAssociationClient;
      this.secondRefAssociationProfessional = info.secondRefAssociationProfessional;
      this.secondRefAssociationColleague = info.secondRefAssociationColleague;
      this.finalAgreement = info.finalAgreement;
      this.firstRefName = info.firstRefName;
      this.firstRefStreet = info.firstRefStreet;
      this.firstRefLocality = info.firstRefLocality;
      this.firstRefTown = info.firstRefTown;
      this.firstRefLandmark = info.firstRefLandmark;
      this.firstRefState = info.firstRefState;
      this.firstRefPincode = info.firstRefPincode;
      this.firstRefCountry = info.firstRefCountry;
      this.firstRefPhoneNumber = info.firstRefPhoneNumber;
      this.firstRefEmail = info.firstRefEmail;
      this.firstRefBlock = info.firstRefBlock;
      this.secondRefBlock = info.secondRefBlock;
      this.secondRefName = info.secondRefName;
      this.secondRefStreet = info.secondRefStreet;
      this.secondRefLocality = info.secondRefLocality;
      this.secondRefTown = info.secondRefTown;
      this.secondRefLandmark = info.secondRefLandmark;
      this.secondRefState = info.secondRefState;
      this.secondRefPincode = info.secondRefPincode;
      this.secondRefCountry = info.secondRefCountry;
      this.secondRefPhoneNumber = info.secondRefPhoneNumbr;
      this.secondRefEmail = info.secondRefEmail;
      this.downloadURL = info.docLink;
      this.fileName = info.docName.split('@');
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await type.get();
      const info = doc.data();
      this.refKey = info.referenceDetails;
    },
  },
  computed: {
    // hmmmn
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getReferencesDetails();
        }
      }
    },
  },
  mounted() {
    this.getRefKey();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-width: 0;
  outline: none;
  display: inline-flex;
  align-items: center;
  font:bold;
}
.btn > * {
  position: relative;
}
.btn span {
  display: block;
}
.btn:before {
  content: "";
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  padding-top: 0;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.btn:active:before {
  width: 120%;
  padding-top: 120%;
  transition: width .2s ease-out, padding-top .2s ease-out;
}
#centerDiv {
  display: flex;
  justify-content: center;
}
#non-printable { display: inherit; height: 3%;width: inherit;text-align: inherit;vertical-align: inherit;}
@media print
  {
        .btn { display: none; }
  }
</style>
