<template>
  <v-form
            ref="formFamilyDetails"
            v-model="validFamilyDetails"
            readonly
            >
        <div class="container">
          <div>
            <label for="mother"><b>Mother's details</b></label>
            <v-text-field
              name="motherName"
              v-model="motherName"
              label="Name"
              id="motherName"
            ></v-text-field>
            <v-text-field
              name="motherMobile"
              v-model="motherMobile"
              label="Phone/Mobile"
              id="motherMobile"
            ></v-text-field>
            <v-text-field
              name="motherDOB"
              v-model="motherDOB"
              label="Date of Birth"
              id="motherDOB"
            ></v-text-field>
            <v-text-field
              name="motherOccupation"
              v-model="motherOccupation"
              label="Occupation"
              id="motherOccupation"
            ></v-text-field>
            <v-text-field
              name="motherAnnualIncome"
              v-model="motherAnnualIncome"
              label="Annual Income(₹)"
              id="motherAnnualIncome"
            ></v-text-field>
          </div>
          <div>
            <label for="father"><b>Father's details</b></label>
            <v-text-field
              name="fatherName"
              v-model="fatherName"
              label="Name"
              id="fatherName"
            ></v-text-field>
            <v-text-field
              name="fatherMobile"
              v-model="fatherMobile"
              label="Phone/Mobile"
              id="fatherMobile"
            ></v-text-field>
            <v-text-field
              name="fatherDOB"
              v-model="fatherDOB"
              label="Date of Birth"
              id="fatherDOB"
            ></v-text-field>
            <v-text-field
              name="fatherOccupation"
              v-model="fatherOccupation"
              label="Occupation"
              id="fatherOccupation"
            ></v-text-field>
            <v-text-field
              name="fatherAnnualIncome"
              v-model="fatherAnnualIncome"
              label="Annual Income(₹)"
              id="fatherAnnualIncome"
            ></v-text-field>
          </div>
          <v-radio-group v-model="maritalStatus"
      required>
       <label><b>Marital Status</b></label>
        <v-radio
          label= "Married"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "Single"
          :value= "false"
        ></v-radio>
      </v-radio-group>
          <div v-show="this.maritalStatus" >
            <label for="spouse"><b>Spouse's details</b></label>

            <v-text-field
              name="spouseName"
              v-model="spouseName"
              label="Name"
              id="spouseName"
            ></v-text-field>
            <v-text-field
              name="spouseMobile"
              v-model="spouseMobile"
              label="Phone/Mobile"
              id="spouseMobile"
            ></v-text-field>
            <v-text-field
              name="spouseDOB"
              v-model="spouseDOB"
              label="Date of Birth"
              id="spouseDOB"
            ></v-text-field>
            <v-text-field
              name="spouseOccupation"
              v-model="spouseOccupation"
              label="Occupation"
              id="spouseOccupation"
            ></v-text-field>
            <v-text-field
              name="spouseAnnualIncome"
              v-model="spouseAnnualIncome"
              label="Annual Income(₹)"
              id="spouseAnnualIncome"
            ></v-text-field>
          </div>
      <v-radio-group v-model="sibbling">
       <label><b>Do you have siblings</b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
    </v-radio-group>
    <label v-if="brothers.length > 0"><b>Brothers details</b></label>
      <v-data-table
      v-if="brothers.length > 0"
      :headers="headers"
      :items="brothers"
      :server-items-length="brothers.length"
      :hide-default-footer="true"
      hide-action
      item-key="item.name">
      <template v-slot:item.dob="props">
        {{ props.item.dob == '' ? "not provided" : formatedDate(props.item.dob) }}
      </template>
    </v-data-table>
    <label v-if="sisters.length > 0"><b>Sisters details</b></label>
      <v-data-table
      v-if="sisters.length > 0"
      :headers="headers"
      :items="sisters"
      :server-items-length="sisters.length"
      :hide-default-footer="true"
      hide-action
      item-key="item.name">
      <template v-slot:item.dob="props">
        {{ props.item.dob == '' ? "not provided" : formatedDate(props.item.dob) }}
      </template>
    </v-data-table>
    <label v-if="sons.length > 0"><b>Sons details</b></label>
      <v-data-table
       v-if="sons.length > 0"
      :headers="headers"
      :items="sons"
      :server-items-length="sons.length"
      :hide-default-footer="true"
      item-key = "item.name"
      hide-action>
      <template v-slot:item.dob="props">
        {{ props.item.dob == '' ? "not provided" :formatedDate(props.item.dob) }}
      </template>
    </v-data-table>
    <label v-if="daughters.length > 0"><b>Daughters details</b></label>
      <v-data-table
       v-if="daughters.length > 0"
      :headers="headers"
      :items="daughters"
      :server-items-length="daughters.length"
      :hide-default-footer="true"
      hide-action
      item-key="item.name">
      <template v-slot:item.dob="props">
        {{ props.item.dob == '' ? "not provided" :formatedDate(props.item.dob) }}
      </template>
    </v-data-table>
        </div>
        </v-form>
</template>
<script>
import moment from 'moment';

export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    validRemunerationDetails: true,
    validExpereinceDetails: true,
    validFamilyDetails: true,
    maritalStatus: false,
    sibbling: false,
    name: '',
    loading: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    submittedId: '',
    brothers: [],
    sisters: [],
    sons: [],
    daughters: [],
    nextBarId: 1,
    headers: [
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: false,
      },
      { text: 'Phone num', value: 'mobNum' },
      { text: 'Date of Birth', value: 'dob' },
      { text: 'Occupation', value: 'occupation' },
      { text: 'Annual Income', value: 'annualIncome' },
    ],
    motherName: '',
    motherMobile: '',
    motherDOB: '',
    motherOccupation: '',
    motherAnnualIncome: '',
    fatherName: '',
    fatherMobile: '',
    fatherDOB: '',
    fatherOccupation: '',
    fatherAnnualIncome: '',
    spouseName: '',
    spouseMobile: '',
    spouseDOB: '',
    spouseOccupation: '',
    spouseAnnualIncome: '',
    error: {
      status: false,
      message: '',
    },
    success: {
      status: false,
      message: '',
    },
    refKey: '',
  }),
  methods: {
    validateFamilyDetails() {
      if (this.$refs.formFamilyDetails.validate()) {
      //  this.submitFamilyDetails();
      }
    },
    async getFamilyDetails() {
      const familyDetails = await this.firebase.firestore().collection('familyDetails').doc(this.refKey);
      const doc = await familyDetails.get();
      const info = doc.data();
      this.brothers = info.brothers;
      this.sisters = info.sisters;
      this.sons = info.sons;
      this.daughters = info.daughters;
      this.maritalStatus = info.maritalStatus;
      this.sibbling = info.sibbling;
      this.motherName = info.motherName;
      this.motherMobile = info.motherMobile;
      this.motherDOB = info.motherDOB;
      this.motherOccupation = info.motherOccupation;
      this.motherAnnualIncome = info.motherAnnualIncome;
      this.fatherName = info.fatherName;
      this.fatherDOB = info.fatherDOB;
      this.fatherMobile = info.fatherMobile;
      this.fatherOccupation = info.fatherOccupation;
      this.fatherAnnualIncome = info.fatherAnnualIncome;
      this.spouseName = info.spouseName;
      this.spouseMobile = info.spouseMobile;
      this.spouseDOB = info.spouseDOB;
      this.spouseOccupation = info.spouseOccupation;
      this.spouseAnnualIncome = info.spouseAnnualIncome;
    },
    async getRefKey() {
      // console.log('getting type');
      const refResponse = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await refResponse.get();
      const info = doc.data();
      this.refKey = info.familyDetails;
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getFamilyDetails();
        }
      }
    },
  },
  computed: {
    formattedDate() {
      return this.theDate ? moment(this.theDate).format('DD/MMM/YYYY') : moment(new Date()).format('DD/MMM/YYYY'); // Custom format
    },
  },
  mounted() {
    this.getRefKey();
  },
};
</script>
