<template>
<v-form
          ref="formExpereinceDetails"
      v-model="validExpereinceDetails"
      readonly
        >
        <div class="container">
        <v-radio-group v-model="fresher">
       <label><b> Are you a Fresher</b></label>
        <v-radio
          label= "Yes"
          :value= "true"
        ></v-radio>
        <v-radio
          label= "No"
          :value= "false"
        ></v-radio>
        </v-radio-group>
      <div v-if="fresher">
        <label><b> Please provide internship details (if any) </b></label>
          {{internshipDetails}}
          <br>
          <br>
          <br>
       <v-divider></v-divider>
      </div>
      <div v-if="!fresher">
      <v-data-table
       v-show="experience.length>0"
      :headers="experienceheaders"
      :items="experience"
      :server-items-length="experience.length"
      :hide-default-footer="true"
      hide-action
      item-key="props.index">
      <template v-slot:item.fromDate="props">
        {{ props.item.fromDate == '' ? "not provided" : formatedDate(props.item.fromDate) }}
      </template>
      <template v-slot:item.toDate="props">
        {{ props.item.toDate == '' ? "not provided" : formatedDate(props.item.toDate) }}
      </template>
    </v-data-table>
    <!-- <v-layout align-start justify-start>
       <v-btn class="mr-4" @click="addExpRow()"><v-icon dark right>mdi-plus</v-icon></v-btn>
    </v-layout> -->
    <br><br><br>
    <v-divider>
    </v-divider>
    <label><b>DETAILS OF PRESENT APPOINTMENT </b></label>
    <v-text-field
      label="Name of Current Organization"
      v-model="currentOrganizationName"
    ></v-text-field><v-text-field
      label="Address of Current Organization"
      v-model="organizationAddress"
    ></v-text-field><v-text-field
      label="Nature of Business / Industry"
      v-model="businessNature"
    ></v-text-field><v-text-field
      label="Total Number of Employees"
      v-model="totalEmployees"
    ></v-text-field><v-text-field
      label="Designation on Joining"
      v-model="designationOnJoining"
    ></v-text-field><v-text-field
      label="Designation at Present"
      v-model="designationAtPresent"
    ></v-text-field><v-text-field
      label="Reporting Officer Name"
      v-model="reportingOfficerName"
    ></v-text-field><v-text-field
      label="Reporting Officer Designation"
      v-model="reportingOfficeDesignation"
    ></v-text-field><v-text-field
      label="Monthly Fixed Take Home"
      v-model="monthlyFixedTakeHome"
    ></v-text-field><v-text-field
      label="Annual Sales Turnover"
      v-model="annualSalesTurnover"
    ></v-text-field><v-text-field
      label="Effective Date"
      v-model="effectiveDate"
    ></v-text-field><v-text-field
      label="Reporting Officer's Mobile No."
      v-model="reportingOfficerMobile"
    ></v-text-field><v-text-field
      label="Office Tel No."
      v-model="officeTelephoneNumber"
    ></v-text-field><v-text-field
      label="Yearly Fixed CTC"
      v-model="yearlyFixedCTC"
    ></v-text-field>
    <v-divider>
    </v-divider>
    <label><b>Outline your Job Responsibilities, Reporting Relationship
      (i.e. to whom you are responsible and who all are responsible to you) and Product / Division (s).
      Major achievements in your current assignment or in the last 2 years.
      (if presently unemployed, give details of your last assignment with joining & leaving dates) </b></label>
      <br>
      {{jobResponsibilities}}
      <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    </div>
    <label><b>Mention any other information, which you think, should be taken into account in considering your application.
      For example : strengths & weaknesses; reasons for seeking change etc.  </b></label>
      <br>
    {{strengthsAndWeakness}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    <label><b>What did you achieve in your last job? [ please specify the details ]  </b></label>
    <br>
    {{acheivementDetails}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    <label><b>Tell us about a big challenge/difficulty you have faced, how did you deal with it?  </b></label>
    <br>
    {{challengesFaced}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    <label><b>What are your personal goals and how do you think that MANIPAL GROUP can help you in realizing your goals ?  </b></label>
    <br>
    {{personalGoals}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    <label><b>What do you think about your last boss / employer ?  </b></label>
    <br>
    {{lastBossThoughts}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
    <label><b>How many years you are willing to commit with us. </b></label>
    <br>
    {{yearsCommitting}}
    <v-divider>
    </v-divider>
    <br>
    <br>
    <br>
        </div>
        </v-form>
</template>

<script>
import moment from 'moment';

export default {
  props: { currentStep: Number, docKey: String },
  data: () => ({
    validExpereinceDetails: true,
    loading: false,
    basicRules: [
      (v) => !!v || 'Field is required',
    ],
    nameRules: [
      (v) => !!v || 'Field is required',
      (v) => (v.split(' ').length <= 3) || 'Maximum only two blank space allowed',
      (v) => /[A-Z ]/gi.test(v) || 'Field cannot consist of digits or special characters',
    ],
    emailRules: [
      (v) => !!v || 'Field is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    textRules: [
      (v) => !!v || 'Field is required',
      (v) => /[A-Z]/gi.test(v) || 'Field only accepts numbers and text',
    ],
    phoneRules: [
      (v) => (v.length === 10 || v.length === 0) || 'Please enter valid phone number',
    ],
    mobRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length >= 12) || 'Please enter valid phone number',
    ],
    digitRules: [
      (v) => !!v || 'Field is required',
      (v) => /^\d+$/.test(v) || 'Field only accepts numbers',
    ],
    experienceheaders: [
      { text: 'Name of Organization', value: 'name' },
      { text: 'Last Designation', value: 'designation' },
      { text: 'Employee Code', value: 'code' },
      { text: 'Worked From(Date)', value: 'fromDate' },
      { text: 'Worked Till (Date)', value: 'toDate' },
      { text: 'Yearly Fixed CTC (Rs.)', value: 'ctc' },
      { text: 'Monthly Fixed Take Home(Rs.)', value: 'takeHome' },
      { text: 'Actions', value: 'action' },
    ],
    submittedId: '',
    fresher: null,
    internshipDetails: '',
    experience: [],
    currentOrganizationName: '',
    organizationAddress: '',
    businessNature: '',
    totalEmployees: '',
    designationOnJoining: '',
    designationAtPresent: '',
    reportingOfficerName: '',
    reportingOfficeDesignation: '',
    monthlyFixedTakeHome: '',
    annualSalesTurnover: '',
    effectiveDate: '',
    reportingOfficerMobile: '',
    officeTelephoneNumber: '',
    yearlyFixedCTC: '',
    jobResponsibilities: '',
    strengthsAndWeakness: '',
    acheivementDetails: '',
    challengesFaced: '',
    personalGoals: '',
    lastBossThoughts: '',
    yearsCommitting: '',
    refKey: '',
  }),
  methods: {
    validateExperienceDetails() {
      if (this.$refs.formExpereinceDetails.validate()) {
      //  this.submitExperienceDetails();
      }
    },
    formatedDate(date) {
      if (typeof date === 'string') {
        // console.log('for ', date, ' it is', moment(date).format('DD-MMM-YYYY'));
        return moment(date).format('DD-MMM-YYYY');
      }
      return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    async getExperienceDetails() {
      const pastExperienceDetails = await this.firebase.firestore().collection('previousExperience').doc(this.refKey);
      const doc = await pastExperienceDetails.get();
      const info = doc.data();
      this.experience = info.experience;
      this.currentOrganizationName = info.currentOrganizationName;
      this.organizationAddress = info.organizationAddress;
      this.businessNature = info.businessNature;
      this.totalEmployees = info.totalEmployees;
      this.designationOnJoining = info.designationOnJoining;
      this.designationAtPresent = info.designationAtPresent;
      this.reportingOfficerName = info.reportingOfficerName;
      this.reportingOfficeDesignation = info.reportingOfficeDesignation;
      this.monthlyFixedTakeHome = info.monthlyFixedTakeHome;
      this.annualSalesTurnover = info.annualSalesTurnover;
      this.effectiveDate = info.effectiveDate;
      this.reportingOfficerMobile = info.reportingOfficerMobile;
      this.officeTelephoneNumber = info.officeTelephoneNumber;
      this.yearlyFixedCTC = info.yearlyFixedCTC;
      this.jobResponsibilities = info.jobResponsibilities;
      this.strengthsAndWeakness = info.strengthsAndWeakness;
      this.acheivementDetails = info.acheivementDetails;
      this.challengesFaced = info.challengesFaced;
      this.personalGoals = info.personalGoals;
      this.lastBossThoughts = info.lastBossThoughts;
      this.yearsCommitting = info.yearsCommitting;
      this.fresher = info.fresher;
      this.internshipDetails = info.internshipDetails;
    },
    deleteExpRow(index) {
      this.experience.splice(index, 1);
    },
    addExpRow() {
      const newRow = {
        yearFrom: '',
        yearTo: '',
        specialization: '',
        institute: '',
        marks: '',
        id: this.experience.length,
      };
      this.experience.push(newRow);
    },
    async getRefKey() {
      // console.log('getting type');
      const type = await this.firebase.firestore().collection('allSectionsIDmap').doc(this.docKey);
      const doc = await type.get();
      const info = doc.data();
      this.refKey = info.previousExperience;
    },
  },
  watch: {
    refKey() {
      if (this.refKey !== undefined) {
        if (this.refKey !== '') {
          this.getExperienceDetails();
        }
      }
    },
  },
  mounted() {
    this.getRefKey();
  },
  computed: {
    // hmmn
  },
};
</script>
